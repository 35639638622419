// changed template.html in fileadmin/templates/tx_pluploadfe/Templates to this file

// ###TEMPLATE_CODE###
// http://www.plupload.com/docs/v2/pluploadQueue


(function($) {
    // Ensure the document is ready before executing
	jQuery(function () {

        // Create the template content
		var uploaderDiv = $("<div id='uploader_UID'></div>");
		uploaderDiv.append('<p>###INFO_1###</p>');
		uploaderDiv.append('<p>###INFO_2###</p>');

		// Append the template content to the body
		$('#lea-upload-modal-slot').append(uploaderDiv);
		var errorMsg = "Error: ", json,
			uploader = $('#uploader_UID').pluploadQueue({

				// General settings
				//runtimes : 'html5,flash,silverlight,html4',
				runtimes : 'html5,html4',
				url : '###UPLOAD_FILE###',
				//chunk_size : '###FILE_MAX_SIZE###',
				//max_file_size: '6mb',
				unique_names : false,
				rename: true,
				//prevent_duplicates: true,
				dragdrop: true,
				multiple_queues: true,
				quality: 100,

				// Specify what files to browse for
				filters : {
					// Remove this when using chunked uploads
					max_file_size : '15mb', //'###FILE_MAX_SIZE###',
					mime_types: [
						{
							title : "Allowed files",
							//extensions : "###FILE_EXTENSIONS###"
							extensions : "jpeg,jpg,png,pdf,doc,xlf,ppt"
						}
					]
				},

				// init : {
				// 	Error: function(up, error) {
				// 		json = $.parseJSON(error.response);
				// 		if (json.error) {
				// 			error.message += " " + json.error.message;
				// 		}

				// 		alert(error.message);
				// 	},
				// 	FileUploaded: function(up, file, info) {
				// 		json = $.parseJSON(info.response);
				// 		console.log(file);
						
				// 		if (json.error) {
				// 			file.status = plupload.FAILED;
				// 			errorMsg += json.error.message;
				// 			alert(errorMsg);
				// 		}
				// 	}
				// }
			});
	});
})(jQuery);
// ###TEMPLATE_CODE###
