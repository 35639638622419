const Counters = document.querySelectorAll(".counter");

if (Counters) {

    Counters.forEach(counter => {

        // How long you want the animation to take, in ms
        const animationDuration = 2000;
        // Calculate how long each ‘frame’ should last if we want to update the animation 60 times per second
        const frameDuration = 1000 / 60;
        // Use that to calculate how many frames we need to complete the animation
        const totalFrames = Math.round( animationDuration / frameDuration );
        // An ease-out function that slows the count as it progresses
        const easeOutQuad = t => t * ( 2 - t );
        
        // The animation function, which takes an Element
        const animateCountUp = el => {
            let frame = 0;
            const countTo = parseInt( el.innerHTML, 10 );
            // Start the animation running 60 times per second
            const counter = setInterval( () => {
                frame++;
                // Calculate our progress as a value between 0 and 1
                // Pass that value to our easing function to get our
                // progress on a curve
                const progress = easeOutQuad( frame / totalFrames );
                // Use the progress value to calculate the current count
                const currentCount = Math.round( countTo * progress );

                const countThousand = numberWithCommas(currentCount)
                // If the current count has changed, update the element
                if ( parseInt( el.innerHTML, 10 ) !== currentCount ) {
                    el.innerHTML = countThousand;
                }
                
                // If we’ve reached our last frame, stop the animation
                if ( frame === totalFrames ) {
                    clearInterval( counter );
                }
            }, frameDuration );
        };

        function numberWithCommas(number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
                
        const runAnimations = (counter) => {
            const countupEls = counter.querySelectorAll('.counter__title');
            countupEls.forEach(animateCountUp);
        };
        
        let animationExecuted = false;
        // check for scrolling
        document.addEventListener('scroll', function() {
            const rect = counter.getBoundingClientRect();
            const top = rect.top;
            const bottom = rect.bottom;

            // If counter element in viewport
            if (top < window.innerHeight && bottom >= 0 && !animationExecuted) {
                runAnimations(counter); 
                animationExecuted = true;
            }
        });
    });
}
