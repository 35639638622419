

	
(function(){
	
	///////////////////////////////////////////////
	//            Beautify                      //
	/////////////////////////////////////////////
	
	// autosize($('textarea.form-control').css('max-height','18.5em'));
	
	// Leere kleine Editfeldbezeichner löschen, um Abstände zwischen großen Bezeichnern und Editfeldern zu verringern
	$('.form-group .control-label:empty').replaceWith('');
	

	///////////////////////////////////////////////
	//            Registrieren                  //
	/////////////////////////////////////////////

	var li_zuord  = $('ul.femanager_error li.femanager_Zuordnung:first');
	var li_zuord2 = $('ul.femanager_error li.femanager_Zuordnung2:first');
	
	if(  (li_zuord.length + li_zuord2.length) > 0 )  {

		$(	
		  '<div id="modal-femanager" class="modal fade" role="dialog">' +
		  '  <div class="modal-dialog">' +
		  '    <div class="modal-content">' +
		  '      <div class="modal-header">' +
		  '        <button type="button" class="close" data-dismiss="modal">&times;</button>' +
		  '        <h1 class="modal-title">Zuordnung Ihrer Bewerbung</h1>' +
		  '      </div>' +
		  '      <div class="modal-body">' +
		  '        <p> ' + ((li_zuord2.length==0) ? li_zuord.text() : li_zuord2.text() ) + ' </p>' +
		  '      </div>' +
		  '      <div class="modal-footer">' +
		  ((li_zuord2.length==0) ?
			'        <button                      type="button" class="btn btn-default" data-dismiss="modal">Angaben &uuml;berpr&uuml;fen</button>' :
			'        <button id="femanager-login" type="button" class="btn btn-default" data-dismiss="modal">Jetzt einloggen!</button>') +
		  '        <button id="femanager-lea-team"     type="button" class="btn" data-dismiss="modal">Hilfe vom Lea-Team</button>' +
		  '      </div>' +
		  '    </div>' +
		  '  </div>' +
		  '</div>'
		).appendTo('body');

		$('#modal-femanager').modal();
		
		$('#femanager-lea-team').click(function(){
			window.location.href = "/lea-bw/kontakt";
		});
		
		$('#femanager-login').click(function(){
			window.location.href = "/index.php?id=24";
		});
	}
	
	// Fehlermeldungen
	$('li.femanager_:contains("agbgelesen")')   .text('Datenschutz: Bitte lesen und bestätigen Sie unsere Datenschutz-Richtlinie');
	$('li.femanager_:contains("infoszusenden")').text('Infos: Bitte bestätigen Sie, dass wir ihnen Informationen zuschicken dürfen.');
	$('li.femanager_:contains("datenweitergb")').text('Datenweitergabe: Bitte bestätigen Sie, dass wir Ihre Daten an unser fachliches Begleitinstitut weitergeben dürfen. Sonst können wir Ihre Bewerbung nicht in den Bewertungsprozess überführen.');
	
	//$('.femanager_new > .form-horizontal > fieldset > legend').css('font-weight', 'bold');
	
	$('.femanager_new legend:nth-child(1)').css('font-weight', 'bold');


	///////////////////////////////////////////////
	//            Kontakt-Formular              //
	/////////////////////////////////////////////
	//'href="/fileadmin/user_upload/Lea_PDFs/Lea_Mittelstandspreis_Datenschutzrichtlinien.pdf"' +
	var kontakt_link  = $('input#kontakt-datenschutz ~ span.checkbox-label').replaceWith(
			'<span class="checkbox-label">Ja, ich habe die <a target="_blank" ' +	
			'href="https://www.lea-mittelstandspreis.de/lea-bw/impressum"' +
			'>Datenschutz-Richtline</a> gelesen und ich akzeptiere sie.</span>'
		);
	

  
  
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lightbox
  // http://dimsemenov.com/plugins/magnific-popup/
  // https://github.com/dimsemenov/Magnific-Popup/tree/master/dist
  try{
    $('.ce-gallery').magnificPopup({
      delegate: 'a',
      type: 'image',
      closeOnContentClick: false,
      closeBtnInside: true,
      mainClass: 'mfp-with-zoom mfp-img-mobile',
      image: {
        verticalFit: true
        // ,
        //titleSrc: function(item) {
        //  return item.el.attr('alt') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank"></a>';
        //}
      },
      gallery: {
        enabled: true
      },
      zoom: {
        enabled: true,
        duration: 300, // don't forget to change the duration also in CSS
        opener: function(element) {
          return element.find('img');
        }
      }
      
    });
  }catch(e){}
  
  
  
  ///////// Up-/Down-Buttons //////////////////////
  try{
    var col = "#FFE664";
    
    //
    // button "up"
    //
    $('#website').append(
      '<div id="intro-button-up">' +
        '<canvas id="intro-button-up-canvas" class="special-button" ' +
            'style="width:37px; height:36px; background-color: rgba(128,128,128,0.5); opacity:0.65;" width="37" height="36"></canvas>' +
      '</div>'
    );
    var canvas_btnup = document.getElementById('intro-button-up-canvas');
    var ctx_btnup    = canvas_btnup.getContext('2d');
    ctx_btnup.strokeStyle = col;
    ctx_btnup.lineWidth = 3;
    ctx_btnup.beginPath();
    ctx_btnup.moveTo( 4, 28);
    ctx_btnup.lineTo(19,  7);
    ctx_btnup.lineTo(33, 28);
    ctx_btnup.stroke();
    
    var itc = $('#intro-text-center');
    if(itc.length){
      $('#intro-button-up-canvas').click(function(){
        $('#intro-button-up-canvas').addClass("special-button-click");
        window.setTimeout( function(){
          $('#intro-button-up-canvas').removeClass("special-button-click");
        }, 170);  
      
        var win_height = $(window).height();
        $('html').animate({scrollTop: win_height+'px'}, 650);
        $('body').animate({scrollTop: win_height+'px'}, 650);
      });
    
      //
      // button "down"
      //
      itc.append(
        '<div id="intro-button-down">' +
          '<canvas id="intro-button-down-canvas" class="special-button" style="width:99px; height:36px;" width="99" height="36"></canvas>' +
        '</div>'
      );
      var canvas_btndown = document.getElementById('intro-button-down-canvas');
      if(canvas_btndown){
        var ctx_btndown    = canvas_btndown.getContext('2d');
        ctx_btndown.strokeStyle = col;
        ctx_btndown.lineWidth = 3;
        ctx_btndown.beginPath();
        ctx_btndown.moveTo( 6,  4);
        ctx_btndown.lineTo(49, 31);
        ctx_btndown.lineTo(93,  4);
        ctx_btndown.stroke();
    
        $('#intro-button-down-canvas').click(function(){
          $('#intro-button-down-canvas').addClass("special-button-click");
          window.setTimeout( function(){
            $('#intro-button-down-canvas').removeClass("special-button-click");
        }, 170);    
        
        var win_height = $(window).height();
        $('html').animate({scrollTop: win_height+'px'}, 650);
        $('body').animate({scrollTop: win_height+'px'}, 650);
        });
      }
    }else{
      var nbc          = $('#navbar-container');
      var nbdummy      = $('#navbar-dummy');
      nbc.css('position', 'fixed');
      nbdummy.css('position', 'static');
      
      $('#intro-button-up-canvas').click(function(){
        $('#intro-button-up-canvas').addClass("special-button-click");
        window.setTimeout( function(){
          $('#intro-button-up-canvas').removeClass("special-button-click");
        }, 170);  
      
        var win_height = $(window).height();
        $('html').animate({scrollTop: '0px'}, 650);
        $('body').animate({scrollTop: '0px'}, 650);
      });
    }
  }catch(e){}
  
  
  ///////////////////////////////////////////////////////////////
  //////// adjust header/footer and special-button-up //////////
  /////////////////////////////////////////////////////////////
  
  ////// footer //////
  // Thema: Footer ganz unten, auch bei wenig Content
  //var initial_main_height = $('#main-container').height();
  
  
  var lea_adjust = function(){
    
    var win_width    = $(window).width();
    var win_height   = $(window).height();
    var ypos         = $(window).scrollTop();
    var butup        = $('#intro-button-up');
    var butup_vis    = butup.css('visibility');
    var nbc          = $('#navbar-container');
    var nbc_position = nbc.css('position');
    var nbc_ypos     = nbc.css('top');
    var nbdummy      = $('#navbar-dummy');
    var lea_button   = $('#lea-button');
	var lea_buttonimg= $('#lea-button img');
    var lea_logo     = $('#logo');
	var lea_logo2    = $('#logo2');
	var lea_logo2img = $('#logo2 img');
    var navbar_button= $('button.navbar-toggle');
    var navbar       = $('#navbar.navbar-collapse');	  
    var footer       = $('#lea-footer');
 
    ////// footer //////
	// Thema: Footer ganz unten, auch bei wenig Content
    //var mc_min_height = $(window).height() - parseInt( $('#lea-footer').css('height')) - parseInt($('#navbar-container').css('height'));
    //if( mc_min_height > initial_main_height ){
    //  $('#main-container').height(mc_min_height);
    //}
	
	
    // adjust size
	lea_logo2img.prop('width', '148');
    lea_logo2img.prop('height', '54');
	
	
	
    var itc = $('#intro-text-center');
    if(itc.length){ //// Intro-Page ////
	  // Footer visibility after Intro
	  if(ypos < win_height/2){
	    footer.css('display', 'none');
	  }else{
	    footer.css('display', 'block');
	  }
	
      // fixed/static position for Navbar-Container
      if((ypos >= win_height) && (nbc_position != 'fixed')){
        nbc.css('position', 'fixed');
        nbdummy.css('position', 'static');
      }
      if((ypos < win_height - 40) && (nbc_position == 'fixed')){
        nbc.css('position', 'static');
        nbdummy.css('position', 'fixed');
      }
    
      // visibility up-button
      if((ypos >= (win_height*1.15)) && (butup_vis != 'visible')){
          butup.css('visibility', 'visible');
      }
        if((ypos < (win_height*1.15-20)) && (butup_vis == 'visible')){
          butup.css('visibility', 'hidden');
      }
	  
      //// header-height etc ////
      if((ypos >= (win_height*1.22)) && (nbc_ypos=="0px")){  // 0.22 <--> 1.22
        nbc.animate({
          top: "-140px"
        }, 250);
		
		lea_button.css('visibility', 'hidden'); /*
		lea_button.css({'width':'54px', 'height':'54px', 'margin-right':'-105px'});
		lea_buttonimg.prop('width', '54');
		lea_buttonimg.prop('height', '54'); */
		
		lea_logo .css('visibility', 'hidden');
		if(win_width > 900)
			lea_logo2.css({'display':'block', 'margin-top':'-50px', 'margin-right':'65px', 'float':'right'});
		
        navbar_button.css({
          'position':  'fixed',
          'top':    '-20px',
          'right':    '5px'
        });
        if(win_width <= 766 ) {
          /*lea_button.css('visibility', 'hidden');*/
          lea_logo.css('visibility', 'hidden');
          navbar.css({
            'position':  'fixed',
            'top':    '115px',
            'left':    '5px'
          });
        }
      }
      if((ypos < (win_height*1.17)) && (nbc_ypos=="-140px")){  // 0.17 <--> 1.17
        nbc.animate({
          top: "0px"
        }, 250);

		lea_button.css('visibility', 'visible'); /*
		lea_button.css({'width':'170px', 'height':'170px', 'margin-right':'-30px'});
		lea_buttonimg.prop('width', '170');
		lea_buttonimg.prop('height', '170');*/
		
		lea_logo .css('visibility', 'visible');
		lea_logo2.css({'display':'none'});
		
        navbar_button.css({
          'position':  'relative',
          'top':       'unset',
          'right':     'unset'
        });
        if(win_width <= 766 ) {
          lea_button.css('visibility', 'visible');
          lea_logo.css('visibility', 'visible');

          navbar.css({
            'position':  'absolute',
            'top':       'unset',
            'left':      'unset'
          });
        }
      }
	  
	  
    }else{ ///// all other pages
    
      //// header-height etc ////
      if((ypos >= (win_height*0.22)) && (nbc_ypos=="0px")){  // 0.22 <--> 1.22
        nbc.animate({
          top: "-140px"
        }, 250);
		
		lea_button.css('visibility', 'hidden'); /*
		lea_button.css({'width':'54px', 'height':'54px', 'margin-right':'-105px'});
		lea_buttonimg.prop('width', '54');
		lea_buttonimg.prop('height', '54'); */
		
		lea_logo .css('visibility', 'hidden');
		if(win_width > 900)
			lea_logo2.css({'display':'block', 'margin-top':'-50px', 'margin-right':'65px', 'float':'right'});
		
        navbar_button.css({
          'position':  'fixed',
          'top':    '-20px',
          'right':    '5px'
        });
        if(win_width <= 766 ) {
          /*lea_button.css('visibility', 'hidden');*/
          lea_logo.css('visibility', 'hidden');
          navbar.css({
            'position':  'fixed',
            'top':    '115px',
            'left':    '5px'
          });
        }
      }
      if((ypos < (win_height*0.17)) && (nbc_ypos=="-140px")){  // 0.17 <--> 1.17
        nbc.animate({
          top: "0px"
        }, 250);

		lea_button.css('visibility', 'visible'); /*
		lea_button.css({'width':'170px', 'height':'170px', 'margin-right':'-30px'});
		lea_buttonimg.prop('width', '170');
		lea_buttonimg.prop('height', '170');*/
		
		lea_logo .css('visibility', 'visible');
		lea_logo2.css({'display':'none'});
		
        navbar_button.css({
          'position':  'relative',
          'top':       'unset',
          'right':     'unset'
        });
        if(win_width <= 766 ) {
          lea_button.css('visibility', 'visible');
          lea_logo.css('visibility', 'visible');

          navbar.css({
            'position':  'absolute',
            'top':       'unset',
            'left':      'unset'
          });
        }
      }


    
      // visibility up-button
      if((ypos >= (win_height*0.25)) && (butup_vis != 'visible') && ($('#modalUpload').css('display')==='none')){
        butup.css('visibility', 'visible');
      }
      if((ypos < (win_height*0.25-15)) && (butup_vis == 'visible')){
        butup.css('visibility', 'hidden');
      }
    }
  };
  

  $(window).scroll( lea_adjust );
  $(window).resize( lea_adjust );
  lea_adjust();
  
  
})();

