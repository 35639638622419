const Medias = document.querySelectorAll(".media")

if (Medias) {
    Medias.forEach(media => {  
        mediaControls(media);
    })
}

function mediaControls(item) {
    const button = item.querySelector(".media__checkbox");
    const video = button.closest(".media__video--container").querySelector(".media__video");
    const controls = button.closest(".media__video--container").querySelector(".media__play");
    const isIframe = button.closest(".media__video--container").querySelector("iframe");
    const checkbox = item.querySelector(".media__checkbox");

    if (isIframe) {
        checkbox.classList.add("is__hidden");
        controls.classList.add("is__hidden");
    }

    button.addEventListener('click', function() {
        if (video.paused) {
            
            video.play();
            controls.classList.add("is__hidden");
        } else {
            video.pause();
            
            if (controls.classList.contains("is__hidden")) {
                controls.classList.remove("is__hidden");
            }
        }
    })
}