import Swiper from 'swiper';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

var init = false;
var swiper;
const timeline = document.querySelector(".timeline");

if (timeline) {
    timelineSlider();
    window.addEventListener("resize", timelineSlider);
}

function timelineSlider() {
    if (window.innerWidth > 991) {
        if (!init) {
            init = true;
        swiper = new Swiper('.timeline', {
            direction: 'horizontal',
            slidesPerView: 4,
            breakpoints: {
                1199: {
                  slidesPerView: 5,
                },
            },
        });
    }
} else if (init) {
      swiper.destroy();
      init = false;
    }
}