const Portrait = document.querySelector(".portrait");

if (Portrait) {

    if (window.innerWidth > 991) {
        toggleActiveTab();
    }
    
    window.addEventListener('resize', function() {
        if (window.innerWidth > 991) {
            toggleActiveTab();
        }
    });
}

function toggleActiveTab() {
    const Portraits             = document.querySelectorAll(".portrait__item");
    const firstImageWrapper     = document.querySelector(".portrait__image-wrapper");
    const firstQuoteContainer   = document.querySelector(".portrait__quote-container");

    // activate first item & container on default
    firstImageWrapper.classList.add("portrait__active");
    firstQuoteContainer.classList.add("portrait__show");

    Portraits.forEach(portrait => {
        const image = portrait.querySelector(".portrait__image-wrapper");
        const container = portrait.querySelector(".portrait__quote-container");
        const icon = portrait.querySelector(".portrait__quote--icon-wrapper");
        
        const portraitLeftPos = portrait.getBoundingClientRect().left;
        const firstPortraitLeftPos = document.querySelector(".portrait__item").getBoundingClientRect().left;
        const leftPos = firstPortraitLeftPos  - portraitLeftPos;
        const positiveLeftPos = Math.abs(leftPos) + 70;

        container.style.left = `${leftPos}px`;
        icon.style.left = `${positiveLeftPos}px`;

        image.addEventListener('click', function() {
            if (image.classList.contains("portrait__active")) {
                
                removePortraitStyling();

            } else {
                removePortraitStyling();

                image.classList.add("portrait__active");
                container.classList.add("portrait__show");
            }
        })
    })
}

function removePortraitStyling() {
    const Portraits = document.querySelectorAll(".portrait__item");

    Portraits.forEach(item => {
        const itemImage = item.querySelector(".portrait__image-wrapper");

        itemImage.classList.remove("portrait__active");
        item.querySelector(".portrait__quote-container").classList.remove("portrait__show");
    });
}