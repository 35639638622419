// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const textimageCE = document.querySelector(".textimageslider");

if (textimageCE) {
    textimageSlider();
}

// init Swiper:
function textimageSlider() {
  const swiper = new Swiper('.textimageslider__slider', {
        // Optional parameters
        modules: [Navigation, Pagination],
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        
        // Navigation arrows
        navigation: {
        nextEl: '.textimageslider__button--next',
        prevEl: '.textimageslider__button--prev',
        },
        pagination: {
            el: '.textimageslider__pagination',
        },
    });
}

