// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Declare swiper globally
let swiper;
const ImageSlider = document.querySelector(".imageslider")

if (ImageSlider) {
  imageSlider();
  sliderArrows();
}

// init Swiper:
function imageSlider() {
  swiper = new Swiper('.imageslider', {
    // Optional parameters
    modules: [Navigation, Pagination],
    direction: 'horizontal',
    loop: true,
    slidesPerView: 1,
    
    // Navigation arrows
    navigation: {
      nextEl: '.imageslider__button--next',
      prevEl: '.imageslider__button--prev',
    },
    breakpoints: {
      768: {
          slidesPerView: 2,
      },
      1200: {
      slidesPerView: 3,
      },
  }
});
}

function sliderArrows() {
  const SliderNav = document.querySelector(".imageslider__navigation")
  if (swiper.slides.length < 4) {
    SliderNav.classList.add("is__hidden")
  } else {
    if (SliderNav.classList.contains("is__hidden")) {
      SliderNav.classList.remove("is__hidden")
    }
  }
}
