const Banner = document.querySelector(".banner");
const isBannerRendered = document.querySelector("#elements > .banner");
const elements = document.querySelector("#elements");

if(!isBannerRendered) {
    elements.classList.add("elements--fluid");
}

if (Banner) {
    bannerScroll();
}

function bannerScroll() {
    const bannerHeader = document.querySelector(".header__additional--cta-container");

    document.addEventListener("scroll", function() {
        const bannerHeader = document.querySelector(".header__additional--cta-container");

        if (window.scrollY > 0) {
            bannerHeader?.classList.add("banner__scroll");
        } 
        else {
            bannerHeader?.classList.remove("banner__scroll");
        }
    })

    if (window.scrollY > 0) {
        bannerHeader?.classList.add("banner__scroll");
    }
};