footerAccordion();

function footerAccordion() {
    const Items = document.querySelectorAll(".footer__link--container");

    Items.forEach(item => {

        item.addEventListener('click', function() {
            const subpage = item.querySelector(".footer__subpages");
            const arrow = item.querySelector(".footer__icon--dropdown");

            if (subpage.classList.contains("is__visible")) {
                subpage.classList.remove("is__visible");
                arrow.classList.remove("is__rotating");

            }   else {
                subpage.classList.add("is__visible");
                arrow.classList.add("is__rotating");
            }
        })
    })
}
