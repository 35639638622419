import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../Scss/includes.scss';

window.addEventListener('load', () => {
    const modules = import.meta.glob([
        './Content/*.js',
        './Leaform/**/*.js',
    ]);

    for (const path in modules) {
        new Promise((_res, _rej) => modules[path]())
    }
});
