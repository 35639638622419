const header = document.querySelector(".header");

if (header) {

    headerScroll();
    burgerMenu();
    subpageToggle();
    toggleHeaderSearch();
}


function headerScroll() {
    const logoText  = document.querySelector("#header__logo");
    const logoNoText= document.querySelector("#header__logo--small");

    document.addEventListener("scroll", function() {
        if (window.scrollY > 0) {
            if (!header.classList.contains("header__scroll")) {
                header.classList.add("header__scroll");
                // Change headerLogo
                logoText.classList.add("is__hidden");
                logoNoText.classList.remove("is__hidden");
            }
        }   else {
            if (header.classList.contains("header__scroll")) {
                header.classList.remove("header__scroll")
                // Change headerLogo
                logoText.classList.remove("is__hidden");
                logoNoText.classList.add("is__hidden");
            }
        }
       
    })
};

function burgerMenu() {
    const burger    = document.querySelector(".respNav__burger--container");
    const close     = document.querySelector(".respNav__close");
    const menu      = document.querySelector(".respNav__menu");
    const background= document.querySelector(".respNav__background");
    const body      = document.querySelector("body");
    
    burger.addEventListener('click', function() {
        menu.classList.remove("is__hidden");
        background.classList.remove("is__hidden");
        body.classList.add("body__fixed");
    })

    close.addEventListener('click', function() {
        menu.classList.add("is__hidden");
        background.classList.add("is__hidden");
        body.classList.remove("body__fixed");
    })
}

function subpageToggle() {
    const Icons = document.querySelectorAll(".respNav__menu .navigation__icon.navigation__icon--dropdown");

    Icons.forEach(icon => {
        const subpages = icon.closest(".respNav__item").querySelector(".respNav__subpages");

        icon.addEventListener('click', function() {
            icon.classList.toggle("respNav__rotate");
            subpages.classList.toggle("is__hidden");
        })

    })
}

function toggleHeaderSearch() {
    const headerIcon = document.getElementById("headersearch_icon");
    const headerInput = document.querySelector(".search__content")

    headerIcon.addEventListener('click', function() {
        headerInput.classList.toggle("header__active");
    })
}